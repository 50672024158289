import React from "react";
import root from "window-or-global";
export default class FacebookChat extends React.Component {
  componentDidMount() {
    root.fbAsyncInit = function() {
      // eslint-disable-next-line no-undef
      FB.init({
        xfbml: true,
        version: "v5.0",
      });
    };
    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_GB/sdk/xfbml.customerchat.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, "script", "facebook-jssdk"));
  }
  render() {
    return (<div>
      <div id="fb-root" />
      <div className="fb-customerchat"
        attribution="setup_tool"
        page_id="108347147330539" />
    </div>);
  }
}
