import { gql } from "@apollo/client";

export const getFindBuildingResult = ({data}) => data?.classMethods?.Building?.findByFormattedAddress || [];
export const findBuildingQuery = gql`
query getBuildingByFormattedAddress($address: String) {
  classMethods {
    Building {
      findByFormattedAddress(address: $address) {
        id
        name
        code
        notification
        ewayCountry
        formattedAddress
      }
    }
  }
}

`;

export const checkServiceAvailabilityQuery = gql`query checkServiceAvailability($buildingCode: String, $unitNo: String) {
  classMethods {
    Building {
      checkServiceAvailability(buildingCode: $buildingCode, unitNo: $unitNo) {
        id
        unitNo,
        streetNo
        address
        suburb
        state
        postCode
        isNewConnection
        country {
          id
          name
        }
      }
    }
  }
}`;


export const getBuildingByCodeQuery = gql`
  query getByBuildingCodes($buildingCode: String!) {
    classMethods {
      SystemProvider {
        getByBuildingCodes (buildingCode: $buildingCode) {
          id
          name
          icon
          hoverIcon
          targetUri
          notManaged
        }
      }
    }
  }
`;

export const getBuildingByCodeResult = ({data}) => (data?.classMethods?.SystemProvider?.getByBuildingCodes); 
